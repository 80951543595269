import _ from 'lodash';
import config from '../config';
import { sendSGMail } from './api';
import { formatMoney } from './currency';
import { types as sdkTypes } from './sdkLoader';

const { Money } = sdkTypes;

export const BookingDataThroughSignUpKey = "BDTSUK";
export const BookingDataTempCheckKey = "BDTCK";

export const DeclineOrCancelOptions = [
    "Want some details changed",
    "Price too high",
    "Found a better option",
    "Changed plans",
];

const generateFullDayHours = () => {
    return Array(24).fill('').map((e, i) => `${(i + 1).toString().padStart(2, "0")}:00`);
};

export const generateStartTimes = (date) => {
    const allHours = generateFullDayHours();
    const clonedDate = date ? _.cloneDeep(date) : null;
    const zeroHoursDate = clonedDate ? new Date(clonedDate.setHours(0)) : null;

    if (zeroHoursDate && zeroHoursDate > new Date()) {
        return allHours;
    } else {
        const currentHour = new Date().getHours();
        const nextHour = currentHour + 1;
        return _.slice(allHours, nextHour, 24);
    };
};

export const generateEndTimes = (startDate, endDate, startHour) => {
    const allHours = generateFullDayHours();
    if (startDate && endDate && endDate > startDate) {
        return allHours;
    } else {
        const nextHour = startHour + 1;
        return _.slice(allHours, nextHour, 24);
    };
};

const generateHourlyFormatOfTimeStamp = (stamp) => {
    const hour = new Date(Number(stamp)).getHours();
    const correctHour = hour == 0 ? 24 : hour;
    return `${correctHour.toString().padStart(2, "0")}:00`
};

export const formatRawDataToAddonFormData = (rawData) => {
    const {
        bookingStartDate,
        bookingEndDate,
        bookingStartTime,
        bookingEndTime,
        noOfGuest,
    } = rawData;

    const clonedStartDate = bookingStartDate.date ? _.cloneDeep(bookingStartDate.date) : null;
    const clonedEndDate = bookingEndDate.date ? _.cloneDeep(bookingEndDate.date) : null;

    return {
        startDate: { date: new Date(new Date(clonedStartDate).toDateString()) },
        endDate: { date: new Date(new Date(clonedEndDate).toDateString()) },
        startTime: generateHourlyFormatOfTimeStamp(bookingStartTime),
        endTime: generateHourlyFormatOfTimeStamp(bookingEndTime),
        noOfGuests: Number(noOfGuest),
    }
};

export const formatDBDataToAddonFormData = (dbData) => {
    const {
        startDate,
        endDate,
        startTime,
        endTime,
        noOfGuests,
        totalPrice,
        tax,
        disableRentalFee,
        attachedFileId,
    } = dbData;

    return {
        startDate: { date: new Date(startDate) },
        endDate: { date: new Date(endDate) },
        startTime,
        endTime,
        noOfGuests,
        spaceRentalPrice: totalPrice / 100,
        taxAmount: tax / 100,
        disableSpaceRentalPrice: disableRentalFee,
        attachedFileId,
    }
};

export const attachTimeToDate = (date, time) => {
    const clonedDate = _.cloneDeep(new Date(date));
    const hours = time.split(":")[0];
    return new Date(clonedDate.setHours(Number(hours)));
};

export const getValidOffer = (offers = []) => {
    if (offers.length == 0) {
        return null;
    } else {
        const ExpiryInDays = config.offerExpiryPeriodInDays;

        const lastOffer = offers[offers.length - 1];
        const lastOfferCreationDate = lastOffer.createdAt;
        const clonedCreationDate = _.cloneDeep(new Date(lastOfferCreationDate));
        const expiryDate = new Date(clonedCreationDate.setDate(clonedCreationDate.getDate() + ExpiryInDays));

        if (expiryDate > new Date()) {
            return lastOffer;
        } else {
            return null;
        };
    };
};

export const formatOfferDate = (date) => {
    const dString = new Date(date).toLocaleDateString('en-GB');
    const dTime = new Date(date).toLocaleTimeString('en-US');
    return `${dString} ${dTime}`;
};

export const sendInquiryAcceptedByHostEmail = async (params) => {
    const {
        customerId,
        listingTitle,
        providerName,
        customerName,
        transactionId,
        formattedStartDate,
        formattedEndDate,
    } = params;

    await sendSGMail({
        userId: customerId,
        subject: `Good News! ${providerName} accepted your inquiry for ${listingTitle} - Confirm Now`,
        template: "inquiry-accepted-by-host",
        emailParams: {
            providerName,
            customerName,
            listingTitle,
            transactionId,
            formattedStartDate,
            formattedEndDate,
        },
        text: `Your booking inquiry for ${listingTitle} has been accepted by the host.`,
    });
};

export const sendOfferAcceptedByGuestEmail = async (params) => {
    const {
        providerId,
        listingTitle,
        providerName,
        customerName,
        transactionId,
    } = params;

    await sendSGMail({
        userId: providerId,
        subject: `Individual Offer Accepted for ${listingTitle}`,
        template: "offer-accepted-by-guest",
        emailParams: {
            providerName,
            customerName,
            listingTitle,
            transactionId,
        },
        text: `${customerName} has accepted your individual offer for ${listingTitle}.`,
    });
};

export const sendOfferCreatedEmail = async (params) => {
    const {
        intl,
        offer,
        listingTitle,
        customerId,
        providerName,
        customerName,
        payinTotalAmount,
        fileURL,
    } = params;

    const {
        endDate,
        endTime,
        startDate,
        startTime,
        offerType,
        totalPrice,
        tx_id,
    } = offer;

    const isDefaultOffer = offerType == "default";

    const formattedStartDate = formatOfferDate(attachTimeToDate(new Date(startDate), startTime));
    const formattedEndDate = formatOfferDate(attachTimeToDate(new Date(endDate), endTime));
    const daysToExpiry = config.offerExpiryPeriodInDays;

    const totalToTake = isDefaultOffer ? payinTotalAmount : totalPrice;
    const payinTotal = formatMoney(intl, new Money(totalToTake, 'CHF'));

    if (isDefaultOffer) {
        await sendSGMail({
            userId: customerId,
            subject: `${providerName} has created an individual offer for you!`,
            template: "offer-created",
            emailParams: {
                providerName,
                customerName,
                listingTitle,
                transactionId: tx_id,
                formattedStartDate,
                formattedEndDate,
                daysToExpiry,
                payinTotal,
            },
            text: `An individual offer has been created for your inquiry about ${listingTitle}.`,
        });
    } else {
        await sendSGMail({
            userId: customerId,
            subject: `${providerName} Has Uploaded a Quote for You!`,
            template: "file-offer-created",
            emailParams: {
                providerName,
                customerName,
                listingTitle,
                transactionId: tx_id,
                formattedStartDate,
                formattedEndDate,
                daysToExpiry,
                payinTotal,
                fileURL,
            },
            text: `${providerName} has uploaded a quote for your inquiry about ${listingTitle}.`,
        });
    };
};

export const addHoursToDate = (hours, date = new Date()) => {
    const clonedDate = _.cloneDeep(new Date(date));
    const newDate = new Date(clonedDate.setHours(clonedDate.getHours() + hours));
    return newDate;
};