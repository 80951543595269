import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ActivityFeed } from '../../components';

import css from './TransactionPanel.module.css';

// Functional component as a helper to build ActivityFeed section
const FeedSection = (props) => {
    const {
        className,
        rootClassName,
        currentTransaction,
        currentUser,
        fetchMessagesError,
        fetchMessagesInProgress,
        initialMessageFailed,
        messages,
        oldestMessagePageFetched,
        onShowMoreMessages,
        onOpenReviewModal,
        totalMessagePages,
        submitG4,
        actionInProgress,
        actionError,
        onEditInquiry,
        onCancelInquiry,
        onSubmitBookingRequest,
        customBreakdownComponent,
        fetchOffersInProgress,
        fetchOffersError,
        offers,
        sendOfferInProgress,
        sendOfferError,
        onSendOffer,
        onUpdateOffer,
        onAcceptFileOffer,
        onUploadOffer,
        tempFileId,
        files,
        sendFileInProgress,
        sendFileError,
        onUploadFile,
        sysMsgs,
        inquiryDataAvailable,
    } = props;

    const txTransitions = currentTransaction.attributes.transitions
        ? currentTransaction.attributes.transitions
        : [];
    const hasOlderMessages = totalMessagePages > oldestMessagePageFetched;

    const showFeed =
        messages.length > 0 ||
        txTransitions.length > 0 ||
        initialMessageFailed ||
        fetchMessagesError;

    const classes = classNames(rootClassName || css.feedContainer, className);

    return showFeed ? (
        <div className={classes}>
            <h3 className={css.feedHeading}>
                <FormattedMessage id="TransactionPanel.activityHeading" />
            </h3>
            {initialMessageFailed ? (
                <p className={css.messageError}>
                    <FormattedMessage id="TransactionPanel.initialMessageFailed" />
                </p>
            ) : null}
            {fetchMessagesError ? (
                <p className={css.messageError}>
                    <FormattedMessage id="TransactionPanel.messageLoadingFailed" />
                </p>
            ) : null}
            <ActivityFeed
                className={css.feed}
                messages={messages}
                transaction={currentTransaction}
                currentUser={currentUser}
                hasOlderMessages={hasOlderMessages && !fetchMessagesInProgress}
                onOpenReviewModal={onOpenReviewModal}
                onShowOlderMessages={onShowMoreMessages}
                fetchMessagesInProgress={fetchMessagesInProgress}
                submitG4={submitG4}
                actionInProgress={actionInProgress}
                actionError={actionError}
                onEditInquiry={onEditInquiry}
                onCancelInquiry={onCancelInquiry}
                onSubmitBookingRequest={onSubmitBookingRequest}
                customBreakdownComponent={customBreakdownComponent}
                fetchOffersInProgress={fetchOffersInProgress}
                fetchOffersError={fetchOffersError}
                offers={offers}
                sendOfferInProgress={sendOfferInProgress}
                sendOfferError={sendOfferError}
                onSendOffer={onSendOffer}
                onUpdateOffer={onUpdateOffer}
                onAcceptFileOffer={onAcceptFileOffer}
                onUploadOffer={onUploadOffer}
                tempFileId={tempFileId}
                files={files}
                sendFileInProgress={sendFileInProgress}
                sendFileError={sendFileError}
                onUploadFile={onUploadFile}
                sysMsgs={sysMsgs}
                inquiryDataAvailable={inquiryDataAvailable}
            />
        </div>
    ) : null;
};

export default FeedSection;
